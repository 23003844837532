<template>
  <div style="background-color: #5e5e5e; height: 800px">
    <el-row>
      <h2>
        <font style="color: #76ba40">欢迎使⽤AI智能统计系统</font>
      </h2>
    </el-row>
    <el-row>
      <font style="color: #ffffff">
        <pre>
这是⼀个强⼤的AI智能运算系统。
为您提供智能化的统计分析服务。
如果您还没有登录权限，请联系我们索要密码。</pre
        >
      </font>
    </el-row>
    <el-row>
      <h4>
        <font style="color: #76ba40">联系我们：</font>
        <font style="color: white"><u>{{ pageInfo.loginEmail }}</u></font>
      </h4>
    </el-row>
    <el-row>
      <h4>
        <font v-if="showError" style="color: red"
          >密码错误，您还有{{ errorTime }}次机会。</font
        >
      </h4>
    </el-row>
    <el-row>
      <el-col :span="12" :offset="6" class="edit-col">
        <el-input placeholder="请输入密码" v-model="password" show-password> </el-input>
        <el-button type="primary" @click="toHome" style="background: #76ba40"
          >登录</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { loginReq, getPageInfo } from "../api/parseContent.js";
export default {
  name: "Login",
  data() {
    return {
      password: "",
      errorTime: 5,
      showError: false,
      pageInfo: {},
    };
  },
  created() {
    this.createPage();
  }  ,
  methods: {
    //点击登录按钮执行访问请求并判断是否登陆
    createPage() {
      getPageInfo().then((res) => {
        // console.log(res.data);
        this.pageInfo = res.data.data;
      });
    },
    toHome() {
      loginReq({ password: this.password })
        .then((res) => {
          //请求成功后执行函数
          console.log(res);
          if (res.code === 0) {
            this.$router.push("/home"); //登录验证成功路由实现跳转
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("endTime", res.data.endTime);
            this.$notify({
              title: "提示",
              message: "用户登录成功",
              duration: 3000,
            });
          } else {
            this.showError = true;
            if (res.code == 10) {
              this.errorTime = res.data;
            }
            this.errorTime = res.data;
            this.$notify({
              title: "提示",
              message: res.msg,
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          //请求错误后执行函数
          this.$notify({
            title: "提示",
            message: "用户访问错误",
            duration: 3000,
          });
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.edit-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
