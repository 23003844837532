import axios from 'axios';
import { req,sendJson,reqSync  } from './axiosFun';


// export const sendContent = (params) => { return req("post", "http://localhost:8888/customer/test", params) };
// export const getPageInfo = () => { return axios.get("http://localhost:8888//customer/page") }
// export const loginReq = (params) => { return req("post", "http://localhost:8888/customer/login", params) };
// export const getCust = (params) => { return req("post", "http://localhost:8888/customer/getCust", params) };
// export const saveCust = (params) => { return sendJson("post", "http://localhost:8888/customer/saveCust", params) };
// export const getClz = (params) => { return req("post", "http://localhost:8888/customer/getClz", params) };
// export const saveClz = (params) => { return sendJson("post", "http://localhost:8888/customer/saveClz", params) };


export const sendContent = (params) => { return req("post", "/customer/test", params) };
export const syncSendContent = async (params) => { return await reqSync("post", "/customer/test3", params) };
export const syncJiaoyang = async (params) => { return await reqSync("post", "/customer/jiaoyang", params) };
export const syncParseContent = async (params) => { return await reqSync("post", "/customer/test2", params) };
export const getPageInfo = () => { return axios.get("/customer/page") }
export const loginReq = (params) => { return req("post", "/customer/login", params) };
export const getCust = async (params) => { return await reqSync("post", "/customer/getCust", params) };
export const saveCust = (params) => { return sendJson("post", "/customer/saveCust", params) };
export const getClz = (params) => { return req("post", "/customer/getClz", params) };
export const saveClz = (params) => { return sendJson("post", "/customer/saveClz", params) };
export const logoutReq = (params) => { return req("post", "/customer/logout", params) };
